<template>
  <div class="conbox">
    <el-upload
      ref="audioUpload"
      v-model="fileList"
      class="upload-demo"
      drag
      :action="actions"
      multiple
      :limit="1"
      :http-request="uploadFile"
      :on-preview="preview"
      :on-exceed="exceed"
    >
      <i class="el-icon-folder-add avatar-uploader-icon"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <template #tip>
        <div class="el-upload__tip dfc">
          <!-- <span>只能上传5MB以内的mp3文件</span> -->
        </div>
      </template>
    </el-upload>

    <el-dialog title="音频试听" :visible.sync="dialogVisible" :destroy-on-close="true" width="30%">
      <div class="dfc" v-if="dialogVisible">
        <audio :src="vdSrc" class="vdBox" autoplay controls></audio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(dialogVisible = false), (vdSrc = '')">取 消</el-button>
        <el-button type="primary" @click="(dialogVisible = false), (vdSrc = '')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { getOss } from "@/api/user.js";
import { formatDate, randomText } from "@/utils/tools";

export default {
  name: "UploadFile",
  data() {
    return {
      actions: process.env.VUE_APP_API_BASE_URL + "/api/local_doc_qa/upload_file",
      fileList: [],
      vdSrc: "",
      music: null,
      duration: null,
      dialogVisible: false,
      data: {
        token: localStorage.getItem("token"),
      },
    };
  },
  methods: {
    uploadFile({ file }) {
      console.log("file", file);

      var audio = new Audio(file.path);
      audio.addEventListener("loadedmetadata", () => {
        this.$refs.audioUpload._data.uploadFiles[0].duration = audio.duration;
        this.duration = audio.duration;
        this.aliOssUpload(file);
      });
    },

    clearFile() {
      this.fileList = [];
      this.$refs.audioUpload._data.uploadFiles = [];
    },

    preview(e) {
      console.log(e);
      // this.dialogVisible = true;
      // this.vdSrc = e.path || e;
      this.paly(e.raw.path);
    },

    paly(url) {
      if (this.music) {
        this.music.src = "";
        this.music = null;
      } else {
        this.music = new Audio(url);
        this.music.play();
        this.music.autoplay = true;
        this.music.loop = false;

        this.music.addEventListener("ended", () => {
          console.log("ended");
          this.music.src = "";
          this.music = null;
          console.log(this.music);
        });
      }
    },

    exceed() {
      this.$message.error("最大允许上传1个文件！");
    },

    async aliOssUpload(file) {
      console.log(file);
      const loading = this.$loading({
        lock: true,
        text: "上传文件中，时间较长请耐心等待",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let date = formatDate(new Date());
      let name = randomText() + ".mp4";
      let dir = `upload/audio/${date}/${name}`;
      await getOss({ dir }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          let form = new FormData();
          form.append("success_action_status", "200");
          form.append("key", dir);
          form.append("OSSAccessKeyId", res.data.accessid);
          form.append("policy", res.data.policy);
          form.append("signature", res.data.signature);
          form.append("file", file);
          axios({
            url: res.data.host,
            method: "POST",
            data: form,
            withCredentials: false,
          }).then((r) => {
            loading.close();

            console.log(r);
            console.log(`${res.data.host}/${dir}`);
            console.log(this.fileList);
            // this.fileList[this.fileList.length == 0 ? 0 : this.fileList.length - 1] = `${res.data.host}/${dir}`;
            this.fileList.push(`${res.data.host}/${dir}`);
            this.$message.success("上传成功");
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.el-upload) {
  display: inherit;
}
.upload-btn {
  width: 180px;
  font-size: 14px;
}
.vdBox {
  width: 315px;
  margin: 0 auto;
}
.conbox {
  padding: 0px;
  width: 260px;
}

.el-upload__tip {
  margin-top: 0;
}

::v-deep {
  .el-upload__text {
    color: #606266;
    font-size: 14px;
    text-align: center;
    margin-top: -20px;
  }
  .el-upload {
    // border: 1px dashed #d9d9d9;
    // border-radius: 6px;
    cursor: pointer;
    // width: 300px;
    // height: 160px;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .el-upload-dragger {
    width: 320px;
    height: 150px;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 260px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
}
</style>

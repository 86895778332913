<template>
  <div class="bg container">
    <div class="conBox">
      <div class="dfc">
        <el-page-header @back="$router.go(-1)" :content="$route.params.detail.kb_name"> </el-page-header>

        <div class="dfc">
          <el-button type="primary" icon="el-icon-upload2" size="small" plain @click="uploadVisible = true">上传文档</el-button>
          <el-button type="primary" icon="el-icon-plus" size="small" plain @click="dialogVisible = true">上传网址</el-button>
        </div>
      </div>

      <div class="mt10">
        <el-table :data="tableData" height="735" style="width: 100%">
          <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
          <el-table-column prop="file_name" label="文档名称"> </el-table-column>
          <el-table-column prop="timestamp" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.timestamp | formatDate }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="文档状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 'red'" style="color: #f56c6c">入库失败-切分失败</span>
              <span v-if="scope.row.status == 'green'" style="color: #67c23a">成功入库</span>
              <span v-if="scope.row.status == 'yellow'" style="color: #e6a23c">入库失败-milvus失败</span>
              <span v-if="scope.row.status == 'gray'" style="color: #909399">正在入库</span>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <span class="cp" style="color: #e13535" @click="handleDel(scope.row.file_id)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="上传文档" :visible.sync="uploadVisible" width="25%" :destroy-on-close="true" append-to-body>
        <div class="formBox">
          <el-upload class="upload-demo" drag name="files" :action="action" :data="uploadData" :on-success="uploadSuccess" :on-error="uploadErr">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="uploadClose">取 消</el-button>
        <el-button type="primary" @click="addItem">保 存</el-button>
      </div> -->
      </el-dialog>
      <el-dialog title="上传网址" :visible.sync="dialogVisible" width="38%" :destroy-on-close="true" append-to-body>
        <div class="formBox">
          <el-input placeholder="请输入内容" maxlength="250" v-model="content"> </el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="editSave">保 存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { get_list_files, upload_weblinks, del_files } from "@/api/miniature.js";
import UploadFile from "@/components/UploadFile.vue";

export default {
  name: "audioLibrary",
  data() {
    return {
      idx: "",
      detail: {},
      id: "",
      user_id: localStorage.getItem("token"),
      localhosts: this.$onlyId,
      title: "添加音频文件",
      dialogVisible: false,
      uploadVisible: false,
      fileName: "",
      content: "",
      action: process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/upload_file",
      form: {
        ipt: "",
      },
      uploadData: {},
      tableData: [],
    };
  },
  filters: {
    formatDate(val) {
      // 提取年、月、日、时、分、秒
      const year = val.substring(0, 4);
      const month = val.substring(4, 6);
      const day = val.substring(6, 8);
      const hour = val.substring(8, 10);
      const minute = val.substring(10, 12);
      const second = "00";

      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
  },
  components: {
    UploadFile,
  },
  mounted() {
    this.user_id = localStorage.getItem("token");
    this.id = this.$route.params.detail.kb_id;
    this.uploadData = {
      user_id: this.user_id,
      localhosts: this.localhosts,
      kb_id: this.id,
      mode: "strong",
    };

    this.getData();
  },
  methods: {
    async getData() {
      let { data: res } = await get_list_files({
        kb_id: this.$route.params.detail.kb_id,
        localhosts: this.localhosts,
        user_id: this.user_id,
      });
      console.log(res);
      if (res.code == 200) {
        // this.$message.success(res.msg);
        this.tableData = res.data.details;
      } else {
        this.$message.error(res.msg);
      }
    },
    uploadSuccess(e) {
      if (e.code == 200) {
        this.$message.success(e.msg);
        this.getData();
        this.uploadClose();
      }
    },
    uploadErr(e) {
      this.$message.success(e.msg);
    },
    async addItem() {
      return;
    },

    async editSave() {
      let { data } = await upload_weblinks({
        user_id: this.user_id,
        localhosts: this.localhosts,
        kb_id: this.id,
        url: this.content,
      });
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.getData();
        this.content = "";
        this.dialogVisible = false;
      } else {
        this.$message.error(data.msg);
      }
    },
    async handleDel(idx) {
      const loading = this.$loading({
        lock: true,
        text: "正在删除中，请稍候",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let { data } = await del_files({
        user_id: this.user_id,
        localhosts: this.localhosts,
        kb_id: this.id,
        file_ids: idx,
      });
      loading.close();
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.getData();
      } else {
        this.$message.error(data.msg);
      }
    },
    close() {
      this.dialogVisible = false;
      this.content = "";
    },
    uploadClose() {
      this.uploadVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.conBox {
  margin: 20px;
  background: #fff;
  padding: 30px;
  min-height: 87vh;
  border-radius: 8px;

  .cHead {
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 700;
    font-size: 16px;
    color: #202020;
    line-height: 19px;
    text-align: left;
  }
}
.formBox {
  padding: 0 20px;
}
::v-deep {
  .el-tabs__item.is-active {
    color: #186df5;
  }
  .el-tabs__active-bar {
    background: #186df5;
  }

  .el-menu {
    width: 92%;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid #186df5;
  }

  .el-textarea__inner {
    border: 0;
    color: #000000;
    background: #f4f5f7;
    padding: 16px 20px !important;
    font-size: 15px !important;
  }
}
</style>
